.nav-main {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    position: relative;
    z-index: 20;

    > ul {
        display: inline-flex;
        align-items: flex-end;

        > li {
            font-size: 2rem;
            position: relative;

            > a {
                border: none;
                color: $brown;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                font-weight: 800;
                text-transform: uppercase;
                opacity: 0.8;
                min-width: 5em;
                transition: $t-medium;

                span {
                    display: block;
                    font-size: 0.9em;
                    pointer-events: none;
                }

                &[hidden] {
                    display: flex !important;
                }

                &:before {
                    font-size: 1.2em;
                    content: "";
                    display: block;
                    background: url("../../Images/Svg/products.svg") no-repeat center bottom;
                    width: 2.25em;
                    height: 3em;
                    margin: 0 auto 0.4em;
                }
            }

            &:nth-child(2) {
                a {
                    &:before {
                        background-image: url("../../Images/Svg/order.svg");
                    }
                }
            }

            &:nth-child(3) {
                a {
                    &:before {
                        background-image: url("../../Images/Svg/contact.svg");
                    }
                }
            }

            &:hover,
            &:focus,
            &:active,
            &.open {
                > a {
                    opacity: 1;
                    color: $green;
                }
            }
        }
    }
}

.nav-item.products {
    cursor: pointer;

    > a {
        pointer-events: none;
    }
}

.layernav {
    position: absolute;
    top: 100%;
    left: 50%;
    color: $brown;
    background: currentColor;
    border-radius: 2px;
    padding: 0.5em 0;
    transition: all 0.4s;
    will-change: transform, opacity;

    &:before {
        content: "";
        position: absolute;
        background: currentColor;
        width: 12px;
        height: 12px;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        opacity: 0;
    }

    li {
        a {
            text-align: center;
            display: block;
            padding: 0.35em 0.75em;
            line-height: 1;
            font-weight: 400;
            color: #fff;
            min-width: 150px;
            transition: color 0.3s;

            &:hover,
            &:focus,
            &:active {
                color: $yellow;
            }
        }
    }

    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, 30px);

    .open & {
        opacity: 1;
        visibility: visible;
        transform: translate(-50%, 10px);

        &:before {
            opacity: 1;
        }
    }

    @media all and (max-width: 649px) {
        top: auto;
        bottom: 100%;
        transform: translate(-50%, -30px);
        border-radius: 2px 2px 0 0;

        &:before {
            top: 100%;
        }

        .open & {
            transform: translate(-50%, calc(#{$mobilenav-topPadding} * -1));
        }
    }

    @media all and (max-width: 480px) {
        padding: 0;
        width: 100%;
        left: 0;
        transform: translateY(calc(#{$mobilenav-topPadding} * -1));
        box-shadow: 0 0 10px $brown-darkest;

        &:before {
            content: none;
        }

        .open & {
            transform: none;
        }

        li {
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);

            a {
                padding: 0.75em;
                font-size: 1.2em;
            }

            &:last-child {
                border: none;
            }
        }
    }
}

