$t-fast: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
$t-medium: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.35s;
$t-mediumslow: all cubic-bezier(0.645, 0.045, 0.355, 1) 0.75s;
$t-slow: all cubic-bezier(0.645, 0.045, 0.355, 1) 1s;
$t-superslow: all cubic-bezier(0.645, 0.045, 0.355, 1) 1.5s;


$green: #a5c500;
$green-dark: #90ad00;
$yellow: #f9b700;

$product_orange: #f18e00;
$product_orange-light: #4a4439;
$product_purple: #2a1d6d;
$product_purple-light: #6a6199;
$product_green: #209245;
$product_green-light: #63b37d;

$brown: #857566;
$brown-light: #e5cfbd;
$brown-lightest: #f5e5d5;
$brown-dark: #6c5947;
$brown-darkest: #3e3328;
$header-bg: #f3ece6;

$font: $brown-dark;
$placeHolderColor: transparentize($brown, 0.25);

$mobilenav-topPadding: 12px;
$maxPageWidth: 1920px;

//Zum ändern der "Papiergröße der Bags im Slider diesen Wert erhöhen:
$paperheight: 20vw;
$paperheight-max: 450px; // Wenn der Viewport-Width > 1920px ist
