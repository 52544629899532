.footer-top {
    padding: 14rem 0;
    background: $brown;
    color: #fff;
    position: relative;

    &:before {
        @include roundSectionBorder-brown;

        top: 2px;
        bottom: auto;
        transform: translateY(-100%);
        background-position: center bottom;
    }

    &:after {
        @include grungeSectionBorder-brown;
    }

    .footer-logo {
        max-width: 330px;
        margin: 0 auto;
        position: relative;
        opacity: 0.5;
    }

    .logo-naturel {
        path,
        polygon,
        rect {
            fill: #fff !important;
        }

        .gradient {
            fill: #fff !important;
        }
    }

    .features {
        position: absolute;
        white-space: nowrap;
        z-index: 2;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 8rem);
        font-size: 1.8em;
        font-weight: 500;
        text-align: center;

        span {
            display: inline-block;
            margin: 0 0.5em;
        }
    }

    @media all and (max-width: 500px) {
        padding: 7rem 3rem;

        .features {
            display: none;
        }

        .footer-logo {
            max-width: 200px;
        }
    }
}

.footer-bottom {
    padding: 7.5em 5rem 5rem;
    background: $green;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-weight: 800;
    font-size: 2rem;
    text-transform: uppercase;
    color: #fff;

    .legalInfo {
        text-align: right;
    }

    .copyright,
    a,
    .legalInfo span {
        opacity: 0.85;
    }

    a {
        color: #fff;
        transition: $t-medium;

        &:hover,
        &:focus,
        &:active {
            opacity: 1;
        }
    }

    .nav-main {
        flex: 0;

        ul {
            li {
                font-size: 1em;

                a {
                    color: #fff;

                    span {
                        font-size: 1em;
                    }
                }

                &:nth-child(1) a:before {
                    background-image: url("../../Images/Svg/products-white.svg");
                }

                &:nth-child(2) a:before {
                    background-image: url("../../Images/Svg/order-white.svg");
                }

                &:nth-child(3) a:before {
                    background-image: url("../../Images/Svg/contact-white.svg");
                }

                ul.layernav {
                    bottom: 100%;
                    top: auto;
                    background: #fff;
                    text-transform: none;
                    transform: translate(-50%, -30px);

                    &:before {
                        top: auto;
                        bottom: 0;
                        transform: translate(-50%, 50%) rotate(45deg);
                        background: #fff;
                    }

                    li {
                        a {
                            color: $brown;
                        }
                    }
                }

                &.open {
                    ul.layernav {
                        transform: translate(-50%, -15px);
                    }
                }
            }
        }
    }

    .copyright {
        font-size: 1em;
        flex: 1;
    }

    .legalInfo {
        flex: 1;
        font-size: 1em;

        span {
            display: inline-block;
            margin: 0 0.5em;
        }
    }

    @media all and (max-width: 1023px) {
        font-size: 2rem;
        padding: 5rem 0 0;
        flex-wrap: wrap;

        .nav-main {
            order: -1;
            flex: 1 0 100%;
            margin-bottom: 3rem;

            ul {
                margin: 0 auto;
            }
        }

        .copyright,
        .legalInfo {
            flex: 1 0 50%;
            max-width: 50%;
            font-size: 0.75em;
            font-weight: 600;
            padding: 1rem 3rem;
        }
    }

    @media all and (max-width: 649px) {
        padding: 5rem 0 14.5rem;

        .nav-main {
            display: none;
        }
    }

    @media all and (max-width: 500px) {
        .copyright,
        .legalInfo {
            font-weight: 500;
            text-transform: none;
            font-size: 15px !important;
            flex: 1 0 100%;
            max-width: 100%;
            text-align: center;
        }

        .legalInfo {
            padding-top: 0;
        }
    }

    @media all and (max-width: 390px) {
        font-size: 1.5rem;
    }
}
