.btn {
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 3.5rem;
    line-height: 1;
    font-weight: 800;
    padding: 0.6em 1.75rem;
    text-align: center;
    border-radius: 3px;
    background: $green;
    color: #fff;
    min-width: 24rem;
    overflow: hidden;

    @media all and (max-width: 1920px) {
        font-size: calc(16px + 1vw);
    }

    @media all and (max-width: 768px) {
        font-size: 2.4rem;
    }

    span,
    .icon {
        position: relative;
        z-index: 10;
        display: inline-block;
        color: currentColor;
    }

    .icon {
        position: absolute;
        right: 0.55em;
        top: 0.3em;
        width: 1em;
        height: 1.5em;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    &:before {
        content: "";
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%) scaleY(0);
        opacity: 0;
        background: $brown;
        transition: $t-fast;
    }

    &:hover,
    &:focus {
        span {
            color: #fff;
        }

        &:before {
            opacity: 1;
            transform: translate(-50%, -50%) scaleY(1);
        }
    }

    &.btn-block {
        display: block;
    }

    &.btn-yellow {
        background-color: $yellow;
    }

    &.btn-brown {
        background-color: transparentize($brown-dark, 0.6);

        &:before {
            background-color: $yellow;
        }
    }

    &.btn-darkbrown {
        background-color: $brown-dark;

        &:before {
            background-color: $green;
        }
    }

    &.btn-order {
        text-align: left;

        .icon {
            background-image: url("../../Images/Svg/order-white.svg");
        }
    }

    &.btn-contact {
        text-align: left;

        .icon {
            background-image: url("../../Images/Svg/contact-white.svg");
        }
    }

    &.btn-products {
        text-align: left;

        .icon {
            background-image: url("../../Images/Svg/products-white.svg");
        }
    }

    &[type="submit"],
    &.openmodal {
        color: #fff !important;
        text-align: center !important;
        padding: 0.6em 1.75rem !important;
        transition: background-color 0.3s;

        &:before,
        &:after {
            display: none;
        }

        &:hover,
        &:focus {
            background: $brown;
        }
    }

    &.openmodal {
        margin-bottom: 3.236em;
        margin-top: -0.809em;
    }

    &.uk-modal-close {
        min-width: 2.25em;
    }
}

.btn-group {
    margin-bottom: 40px !important;

    .btn {
        display: block;
        text-align: left;
        padding-right: 4rem;
    }
}

@media all and (max-width: 479px) {
    .btn {
        margin-left: 0 !important;
        min-width: 100%;
        display: block;
    }
}
