* {
    box-sizing: border-box;
    outline: none !important;

    &:hover,
    &:focus {
        outline: none !important;
    }
}

::-webkit-scrollbar {
    width: 8px;
    background-color: #f2efe8;
}

::-webkit-scrollbar-track {
    border-radius: 0;
    border: none;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background: $brown;
    border-radius: 0;
}

::selection {
    color: $green;
    background: transparent;
}

::-moz-selection {
    color: $green;
    background: transparent;
}

body,
html {
    min-height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: 10px;
}

body {
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-backface-visibility: hidden;
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;
    background: $brown-darkest;

    @include texture-overlay;

    &.noOverflow {
        height: 100vh;
        overflow: hidden;
    }
}

nav {
    ol,
    ul {
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            padding: 0;
            margin: 0;

            a {
                text-decoration: none;
            }
        }
    }
}

img,
svg {
    width: 100%;
    height: auto;
}

img {
    display: block;
}

figure {
    margin: 0;
}

hr {
    border: none;
    border-bottom: 1px solid #000;
    outline: none;
    height: 0;
    padding: 0;
    margin: 0;
}

.touch .mainNav *:not(.burger) {
    &:hover,
    &:focus,
    &:active {
        transition: none !important;
    }
}

