.uk-container {
    &.uk-container-expand {
        width: 100%;
    }

    @media all and (max-width: 649px) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.uk-modal {
    @include texture-overlay;

    &:before {
        opacity: 0.15;
    }
}

.uk-modal-body {
    @media all and (max-width: 1100px) {
        padding-left: 0;
        padding-right: 0;
    }
}

.uk-modal-dialog {
    overflow: hidden;
    width: 100%;
    max-width: 850px !important;
    border-radius: 3px;
    padding: 40px;

    @include patch-big;

    @media all and (max-width: 400px) {
        padding: 20px;
    }

    &:before {
        top: 0;
        left: 100%;
        transform: translate3d(-62%, -45%, 0);
        max-width: 725px;
        max-height: 725px;
    }

    #pageWrap {
        background: none;
        box-shadow: none;
        overflow: visible;

        &:before {
            content: none;
        }
    }

    main {
        margin: 0;
        padding: 0;
    }
}

.uk-modal-close-default {
    svg {
        display: none;
    }

    background: $yellow;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    border-radius: 50%;

    &:hover {
        background: $green;
    }

    &:after {
        content: "×";
        display: block;
        color: #fff;
        font-size: 2.8rem;
        line-height: 0;
    }
}
