
$paperheightNegative: calc(#{$paperheight} * -1);

section.products {
    position: relative;
    z-index: 30;
    margin-top: -23.5%;
    padding: 0;
}

.bags {
    display: flex;
    justify-content: center;

    .uk-slidenav {
        z-index: 5;
        padding: 5px 10px;
        color: $font;
        transition: color 0.1s ease-in-out;
        width: 25%;

        @media all and (max-width: 639px) {
            width: 13.5%;
        }

        @media all and (min-width: 1024px) {
            display: none;
        }

        svg {
            display: none;
        }
    }
}

.bag {
    flex: auto;
    max-width: 467px;
    position: relative;
    margin: 0 2.5rem 5rem;
    cursor: pointer;
    transition: $t-medium;
    will-change: transform;

    @media all and (max-width: 1280px) {
        margin: 0 1rem 5rem;
    }

    &:after {
        content: "";
        position: absolute;
        z-index: 1;
        bottom: -4%;
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center bottom;
    }

    &.center {
        transform: translateY(20%);

        &:after {
            left: -6%;
            width: 112%;
            height: 70%;
            background-image: url("../../Images/bag/shadow-straight.png");
        }
    }

    &.left,
    &.right {
        &:after {
            width: 135%;
            height: 100%;
            left: -9%;
            background-image: url("../../Images/bag/shadow-perspectiv.png");
        }
    }

    &.right {
        &:after {
            transform: scaleX(-1);
            transform-origin: bottom;
            left: auto;
            right: -9%;
        }
    }

    &.rabbit {
        &:hover,
        &.active {
            color: $product_purple;
        }
    }

    &.corn {
        &:hover,
        &.active {
            color: $product_green;
        }
    }

    &.chicken {
        &:hover,
        &.active {
            color: $product_orange;
        }
    }
}

.bagBody {
    position: relative;
    z-index: 5;
    pointer-events: none;

    img {
        position: relative;
        z-index: 5;
    }

    &:before {
        content: "";
        position: absolute;
        z-index: 2;
        top: 0;
        height: 13.8%;
        background: url("../../Images/bag/shadow-paper.png") no-repeat center bottom;
        background-size: 100% auto;
    }
}

.bagLabel {
    position: absolute;
    z-index: 30;
    left: 0.8%;
    bottom: 0;
    width: 98.4%;
    height: 86.3%;
    overflow: hidden;
    transition: all 0.3s;
    will-change: opacity;

    &:before {
        content: "";
        position: absolute;
        right: -50px;
        bottom: 50px;
        width: 200px;
        height: 200px;
        opacity: 0.5;
    }

    > * {
        position: absolute;
    }

    .productName {
        position: relative;
        padding: 9%;
        display: block;

        * {
            transition: all 0.3s;
        }
    }


    .productLogo {
        opacity: 0.6;
    }

    .bag.chicken & {
        .productLogo {
            right: -28%;
            bottom: 5%;
            width: 86%;
            opacity: 0.7;
        }
    }

    .bag.corn & {
        .productLogo {
            right: 3%;
            bottom: 5%;
            width: 53%;
        }
    }

    .bag.rabbit & {
        .productLogo {
            right: -8%;
            bottom: 9%;
            width: 54%;
        }
    }

    .productBadge {
        width: 40%;
        bottom: 24%;
        left: 8%;

        .badgeClaim {
            z-index: 2;
            left: 13%;
            top: 39%;
            position: absolute;
            width: 79%;
        }
    }

    .productWeight {
        left: 8%;
        bottom: 5%;
        width: 14%;
        opacity: 0.6;
    }
}


.bagBody:before,
.bagPaper {
    left: 3%;
    width: 94%;
}

.bagPaper {
    border-radius: 2px;
    height: auto;
    top: 11%;
    position: absolute;
    z-index: 2;
    background: #fff;
    transition: $t-medium;
    font-size: 1rem;
    color: $font;
    will-change: transform;

    .scroller {
        position: relative;
        height: $paperheight;
        overflow: hidden;
        margin-top: calc(10px + 1vw);

        @media all and (min-width: $maxPageWidth) {
            height: $paperheight-max;
        }
    }

    .content {
        transition: opacity 0.2s;
        opacity: 0;
        padding: 0 calc(10px + 1vw);
        box-sizing: content-box;
        margin-bottom: 15rem;
        pointer-events: none;
        will-change: opacity;

        .active & {
            opacity: 1;
            pointer-events: auto;
        }

        @media all and (max-width: 768px) {
            margin-bottom: 13vw;
        }
    }

    .h3 {
        text-transform: uppercase;
        font-size: calc(16px + 0.6vw);
        line-height: 1;
        font-weight: 600;
        margin: 0 0 0.5em;
    }

    p,
    ul li,
    ol li {
        font-size: calc(14px + 0.4vw);
        font-weight: 400;
        line-height: 1.5;
        margin-bottom: 0.405em;
    }

    p {
        margin-bottom: 0.809em;
    }

    .btn {
        display: block;
        min-width: 0;
        font-size: calc(12px + 0.8vw);

        @media all and (min-width: 1921px) {
            font-size: 27px;
        }

        margin: 1em 0;

        + .btn {
            margin-top: -0.5em;
        }
    }
}

.bag:hover {
    .bagPaper .content {
        opacity: 1;
    }
}

.bag.active {
    transform: translateY(15vw);

    .bagPaper {
        transform: translateY($paperheightNegative);
        box-shadow: 0 -0.5em 5.5rem -0.4rem $brown-dark;
    }

    @media all and (min-width: $maxPageWidth) {
        transform: translateY(288px);

        .bagPaper {
            transform: translateY(-$paperheight-max);

            .scroller {
                height: $paperheight-max;
            }
        }
    }
}

@media all and (min-width: 1024px) {
    section.products {
        .uk-slider-container {
            overflow: visible !important;
        }

        .uk-slider-items {
            transform: none !important;
            margin: 0;

            .slide.uk-width-3-4 {
                flex: auto;
                width: auto;
                padding: 0;
            }
        }
    }
}

@media all and (min-width: 1281px) {
    section.products {
        .uk-slider-items {
            margin: 0 2.5rem;
        }
    }
}

@media all and (max-width: 1023px) {
    section.products {
        margin-top: -35%;
    }

    .bag {
        flex: none;
        width: 100%;
        transition: $t-medium;
        margin: 0 0 10vw !important;
        pointer-events: none;

        .bagPaper {
            top: 15.2%;
            height: 83.2%;
            z-index: 50;
            background: none;
            transform: none !important;

            .scroller {
                margin-top: 15px;
                height: calc(100% - 15px);
            }

            .content {
                margin-bottom: 0;
            }

            h3,
            .h3 {
                font-size: calc(22px + 0.8vw);
                color: $brown-darkest;
            }

            p,
            ul li,
            ol li {
                font-size: calc(15px + 0.8vw);
                color: $brown-darkest;
            }

            .btn {
                font-size: calc(16px + 0.8vw);
                opacity: 0.85;
            }
        }

        &.active {
            pointer-events: auto;
            transform: none;

            .bagPaper {
                transform: none;
                box-shadow: none;

                .content {
                    opacity: 1 !important;
                }
            }

            .bagLabel {
                opacity: 0;
            }
        }

        &:hover {
            .bagPaper .content {
                opacity: 0;
            }
        }

        &.center {
            transform: none;
        }
    }

    .uk-active {
        .bag {
            pointer-events: auto !important;
            transform: translateY(8vw) !important;
        }
    }
}

@media all and (max-width: 450px) {
    .bag {
        .bagPaper {
            .btn {
                .icon {
                    display: none;
                }
            }
        }

        &.active {
            pointer-events: auto;
            transform: none;

            .bagPaper {
                transform: none;
                box-shadow: none;

                .content {
                    opacity: 1 !important;
                }
            }

            .bagLabel {
                opacity: 0;
            }
        }

        &:hover {
            .bagPaper .content {
                opacity: 0;
            }
        }

        &.center {
            transform: none;
        }
    }

    .uk-active {
        .bag {
            pointer-events: auto !important;
            transform: translateY(8vw) !important;
        }
    }
}
