section.product {
    p.hint {
        line-height: 1.2;
        font-weight: 500;
    }

    .bag {
        pointer-events: none;
        margin-left: 0;
        margin-right: 0;

        &:after {
            width: 135%;
            height: 100%;
            left: -9%;
            background-image: url("../../Images/bag/shadow-perspectiv.png");
        }

        .bagPaper {
            box-shadow: 0 0 10px transparentize($brown-dark, 0.6);
            height: 11%;
            top: 11%;
            z-index: 2;
            background: #fff;
        }
    }

    .rightContent {
        margin-top: 5%;
    }
}

@media all and (max-width: 649px) {
    section.product {
        .bag {
            margin-left: auto !important;
            margin-right: auto !important;
            max-width: 300px;
        }
    }
}
