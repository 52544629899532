@import "base/variables";

// 1. Your custom variables and variable overwrites.
$global-primary-background: $green;
$inverse-global-color-mode: none;
$base-body-font-family: "naturel", Helvetica, Arial, sans-serif;
$base-heading-font-family: $base-body-font-family;
$primary-font: $base-body-font-family;
$base-body-color: $font;
$base-link-color: $green;
$base-link-hover-color: $yellow;
$base-link-hover-text-decoration: none;
$form-large-font-size: 2rem;
$form-padding-horizontal: 0.75em;
$form-large-padding-horizontal: $form-padding-horizontal;
$form-color : $brown;
$form-focus-color: $font !important;
$form-placeholder-color: $placeHolderColor;
$form-background: transparentize($brown, 0.85);
$form-focus-background: transparentize($yellow, 0.7);
$form-border: transparent;
$form-focus-border: transparent;
$form-radio-size:1em;
$form-radio-checked-focus-background: $yellow;
$form-checkbox-checked-focus-background: $form-radio-checked-focus-background;
$form-checkbox-checked-focus-background: $form-radio-checked-focus-background;
$modal-footer-border: $form-background;

// 2. Import default variables and available mixins.
@import "~uikit/src/scss/variables-theme.scss";
@import "~uikit/src/scss/mixins-theme.scss";

// 3. Your custom mixin overwrites.

// 4. Import UIkit.
//@import "~uikit/src/scss/uikit-theme.scss";
@import "components/uikit";

// Base
@import "base/mixins";
@import "base/uikit-override";
@import "base/base";
@import "base/grid";
@import "base/base";
@import "base/typography";
@import "base/form";
@import "base/button";


// Navigation
@import "nav/nav-main";

// Fonts
@import "fonts/naturel";

// Layout
@import "layout/l-body";
@import "layout/l-header";
@import "layout/l-footer";

// Content Elements
@import "components/sectionHasPatch";
@import "components/contactBox";
@import "content-elements/ce-includes";
@import "content-elements/ce-products";
@import "content-elements/ce-product";

