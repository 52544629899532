// Header
.l-header {
    display: flex;
    justify-content: center;
    background: #fff;
    padding: 3rem 3rem 3.2rem;
    position: relative;
    z-index: 30;

    &:after {
        @include grungeSectionBorder;
    }

    .logo {
        display: block;
        max-width: 28rem;
        position: relative;
        z-index: 30;
    }

    .spacer,
    .nav-main {
        flex: 1;
    }

    .logo-naturel {
        path,
        polygon,
        rect {
            fill: $green;
        }

        .gradient {
            fill: url(#logo-gradient);
        }
    }

    @media all and (max-width: 1279px) {
        padding: 2rem 3rem 2.2rem;

        .spacer {
            display: none;
        }

        .nav-main > ul > li {
            font-size: 1.8rem;
        }

        .logo {
            margin-left: 2.3rem;
            max-width: 23rem;
        }
    }

    @media all and (max-width: 900px) {
        padding: 1.5rem 3rem 1.4rem;

        .logo {
            max-width: 20rem;
            margin-left: 2rem;
        }

        .nav-main ul li {
            font-size: 1.7rem;

            a {
                min-width: 5em;
            }
        }
    }

    @media all and (max-width: 649px) {
        flex-wrap: wrap;
        padding: 1.5rem 3rem 1rem;

        .logo {
            max-width: 22rem;
            margin: 7px 0 10px;
            flex: 1 0 100%;
        }

        .nav-main {
            flex: 1 0 100%;
            width: 100%;
            position: fixed;
            bottom: 0;
            background: #3e3328;
            justify-content: center;
            margin: 0;
            padding: $mobilenav-topPadding 15px 7px 15px;

            > ul {
                display: flex;
                flex: 1;
                justify-content: space-around;

                > li {
                    > a {
                        color: #fff;
                        font-size: 1.1em;

                        span {
                            font-size: 0.9em;
                            font-weight: 500;
                            text-transform: none;
                        }

                        &:before {
                            width: 1.8em;
                            height: 2em;
                            margin: 0 auto 0.25em;
                        }
                    }

                    &.open {
                        > a {
                            color: $green !important;
                            opacity: 1 !important;
                        }
                    }
                }
            }
        }
    }

    @media all and (max-width: 649px) and (max-height: 579px) {
        .nav-main {
            padding-bottom: 9px;

            > ul > li > a:before {
                content: none;
            }
        }
    }

    @media all and (max-width: 480px) {
        .nav-main > ul > li {
            position: static;
        }
    }

    @media all and (max-width: 400px) {
        padding: 1.5rem 1.5rem 0;

        .nav-main > ul {
            justify-content: space-between;
        }
    }
}

body:not(.home) {
    .l-header {
        background: $header-bg;

        &:after {
            background-image: url(../../Images/Bmp/grungeBorderInverse-beige.png);
        }
    }
}
