#pageWrap {
    width: 100%;
    max-width: $maxPageWidth;
    overflow: hidden;
    background: #fff;
    margin: 0 auto;
    position: relative;
    z-index: 10;
    box-shadow: 0 0 20px transparentize(#000, 0.75);

    &:before {
        content: "";
        position: absolute;
        z-index: 10000;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: url(../../Images/Bmp/karton.png);
        pointer-events: none;
        opacity: 0.2;
    }
}

main {
    position: relative;
    z-index: 10;
    padding-bottom: 15rem;
}

.uk-slider-items > li {
    &:before {
        content: none !important;
    }
}

section {
    position: relative;
    padding-bottom: 10rem;

    @media all and (max-width: 1023px) {
        padding-bottom: 5rem;
    }
}

.uk-grid {
    position: relative;
    z-index: 1;
}
//section:not(.hero):not(.products) {
//    + section.textimage:not(.intro){
//        margin-top: -15vw;
//        z-index: 10;
//    }
//}
main {
    section:not(.hero):not(.products) {
        &:first-of-type {
            padding-top: 7vw;

            @media all and(min-width: 1920px) {
                padding-top: 13.5rem;
            }

            @media all and(max-width: 659px) {
                padding-top: 6.5rem;
            }
        }
    }
}
