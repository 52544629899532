select,
input,
textarea,
form,
button,
option {
    font-family: $primary-font;
    font-weight: 400;
    max-width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    font-size: 2rem;
    border-radius: 3px;

    @media all and (max-width: 400px) {
        font-size: 1.7rem;
    }

    ::-webkit-input-placeholder {
        color: $placeHolderColor;
        font-size: 1em;
    }

    :-moz-placeholder {
        color: $placeHolderColor;
        opacity: 1;
        font-size: 1em;
    }

    ::-moz-placeholder {
        color: $placeHolderColor;
        opacity: 1;
        font-size: 1em;
    }

    :-ms-input-placeholder {
        color: $placeHolderColor;
        font-size: 1em;
    }
}

select::-ms-expand {
    display: none;
}

input:not([type="checkbox"]):not([type="radio"]),
select,
textarea {
    border-radius: 3px !important;
    box-sizing: border-box;
    margin: 0;
    font: inherit;
    height: 40px;
    vertical-align: middle;
    display: inline-block;
    max-width: 100%;
    width: 100%;
    padding: 0 0.75em;
    background: rgba(133, 117, 102, 0.15);
    color: #857566;
    border: 1px solid transparent;
    transition: 0.2s ease-in-out;
    transition-property: color, background-color, border;

    &:focus {
        outline: none;
        background-color: rgba(249, 183, 0, 0.3);
        color: $brown !important;
        border-color: transparent;
    }
}

textarea {
    padding: 0.75em;
    min-height: 30rem;
}

input[type="checkbox"],
input[type="radio"] {
    display: inline-block;
    height: 1em;
    width: 1em;
    overflow: hidden;
    margin-top: -4px;
    margin-right: 0.5em;
    vertical-align: middle;
    -webkit-appearance: none;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: none;
    background-color: rgba(133, 117, 102, 0.15);
    transition: 0.2s ease-in-out;
    transition-property: background-color, border;

    &:checked,
    &:indeterminate {
        background-color: $green;
        border-color: transparent;
    }

    &:not(:disabled) {
        cursor: pointer;
    }

    &:checked {
        background-image: url("../../Images/Svg/checkbox.svg");
    }
}

label.control-label {
    margin-bottom: 0.5rem;
    display: block;
    font-weight: 500;
}

.form-check,
.form-group {
    margin: 1rem 0;
}

.form-check-label {
    cursor: pointer;

    &:hover {
        input[type="checkbox"],
        input[type="radio"] {
            background-color: $yellow;
        }
    }
}

.input.checkbox {
    margin: 2rem 0 4rem;
}

input:-internal-autofill-previewed,
input:-internal-autofill-selected,
textarea:-internal-autofill-previewed,
textarea:-internal-autofill-selected,
select:-internal-autofill-previewed,
select:-internal-autofill-selected,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    background-color: transparent !important;
    background-image: none !important;
    color: $font !important;
    -webkit-text-fill-color: $font !important;
}

input:-webkit-autofill {
    color: $font !important;
}

.uk-radio,
.uk-checkbox {
    margin-right: 0.5em;
}
