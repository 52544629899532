
//Paragraph

blockquote,
dd,
div,
dl,
dt,
form,
h1,
h2,
h3,
h4,
h5,
h6,
li,
ol,
p,
pre,
td,
th,
ul {
    margin: 0;
    padding: 0;
}

p {
    b,
    strong {
        font-weight: 500;
    }

    a {
        color: $font;
        //font-weight: 400;
        line-height: inherit;
        cursor: pointer;
        text-decoration: none;
        transition: color 0.25s;
        border-bottom: 0.075em solid $green;

        &:hover,
        &:focus {
            color: $green;
        }

        &:active {
            transition-duration: 0.05s;
            color: $font;
        }
    }
}

main {
    p,
    ol li,
    ul li {
        font-size: calc(16px + 1vw);
        margin-bottom: 1.618em;
        line-height: 1.618;
        font-weight: 300;
        text-rendering: optimizeLegibility;

        @media all and (min-width: 1280px) {
            font-size: 3.2rem;
        }

        @media all and (max-width: 768px) {
            font-size: 2.2rem;
        }
    }

    ul,
    ol {
        li {
            margin-bottom: 0.809em;
            list-style: none;
        }
    }
}

main section:not(.hero) {
    ul {
        li {
            position: relative;
            padding-left: 1em;

            &:before {
                content: "·";
                font-size: 3em;
                position: absolute;
                left: 0;
                top: -0.56em;
            }
        }
    }
}

em,
i {
    font-style: italic;
    line-height: inherit;
}

b,
strong {
    font-weight: 700;
    line-height: inherit;
}

small {
    font-size: 75%;
    line-height: inherit;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
p.introtext {
    font-style: normal;
    font-weight: 300;
    color: inherit;
    line-height: 1.5;
    margin-top: 0;
    text-rendering: optimizeLegibility;
    margin-bottom: 1em;

    &:empty {
        display: none;
    }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.2;
}

h1,
.h1 {
    line-height: 1;
}

.h1,
h1,
.h2,
h2 {
    color: $font;
}

.h1,
h1 {
    font-size: 7.5rem;
    font-style: italic;
    font-weight: 600;
    text-align: center;
    margin: 1em 0 2em;
}

.h2,
h2 {
    font-size: 5rem;
    font-weight: 400;
    font-style: italic;
    margin-bottom: 0.5em;
    color: $green;
}

.h3,
h3 {
    font-size: 3.2rem;
    margin-bottom: 1em;
    font-weight: 600;
}

.h4,
h4 {
    font-size: 2.8rem;
    font-weight: 400;
}

.h5,
h5 {
    font-size: 2.2rem;
    font-weight: 400;
}

.h6,
h6 {
    font-size: 1.6rem;
    font-weight: 600;
}

h1,
.h1 {
    + h2,
    .h2 {
        margin-top: -1.75em;
    }
}

h2,
.h2 {
    + h3,
    .h3 {
        margin-top: -2em;
    }
}

h3,
.h3 {
    + h4,
    .h4 {
        margin-top: -1em;
    }
}

h4,
.h4 {
    + h5,
    .h5 {
        margin-top: -1em;
    }
}

h5,
.h5 {
    + h6,
    .h6 {
        margin-top: -1em;
    }
}

@media print, screen and (max-width: 649px) {
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3 {
        hyphens: auto;
    }

    .h1,
    h1 {
        font-size: 4.8rem;
    }

    .h2,
    h2 {
        font-size: 3.6rem;
    }

    .h3,
    h3 {
        font-size: 2.8rem;
    }

    .h4,
    h4 {
        font-size: 2.4rem;
    }

    .h5,
    h5 {
        font-size: 2rem;
    }

    .h6,
    h6 {
        font-size: 1.6rem;
    }
}

hr {
    clear: both;
    height: 0;
    margin: 2rem auto;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid currentColor;
    border-left: 0;
}

dl,
ol,
ul {
    margin-bottom: 1.6rem;
    list-style-position: outside;
    line-height: 1.6;
}

li {
    font-size: inherit;
}

main {
    ul {
        margin-left: 0;

        li {
            p {
                margin: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

cite:before {
    content: "— ";
}

@media all and (max-width: 499px) {
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    p {
        hyphens: auto;
    }
}
