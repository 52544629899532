.contactBox {
    background: $brown;
    color: #fff;
    padding: 3rem;
    border-radius: 3px;
    margin-bottom: 1.618em;

    h3,
    .h3,
    p {
        font-size: calc(16px + 1vw);
        line-height: 1.45;

        @media (min-width: 1280px) {
            font-size: 3.2rem;
        }

        @media all and (max-width: 768px) {
            font-size: 2.2rem;
        }
    }

    .btn {
        &:before {
            background-color: $yellow;
        }
    }
}

p.recommendation {
    background: #f2ede6;
    padding: 3rem;
    border-radius: 3px;
    color: $brown;
    font-size: calc(14px + 1vw);

    @media (min-width: 1280px) {
        font-size: 3.2rem;
    }

    @media all and (max-width: 768px) {
        font-size: 2.2rem;
    }

    line-height: 1.45;
    margin-bottom: 1.85em;

    &:last-child {
        margin-bottom: 0;
    }

    + p.recommendation {
        margin-top: -6px;
    }
}

