@mixin centerVert {
    left: 50%;
    transform: translateX(-50%);
}

@mixin centerHorz {
    top: 50%;
    transform: translateY(-50%);
}

@mixin centerVertHorz {
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
}

@mixin roundSectionBorder {
    content: "";
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: -5px;
    width: 100%;
    padding-top: 6.41%;
    background: url(../../Images/Bmp/roundBorder-white.png) no-repeat center top;
    background-size: 100% auto;
    pointer-events: none;
}

@mixin roundSectionBorder-brown {
    @include roundSectionBorder;

    background-image: url(../../Images/Bmp/roundBorder-brown.png);
}

@mixin grungeSectionBorder {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 5px;
    transform: translateY(100%);
    width: 100%;
    padding-top: 3.35%;
    background: url(../../Images/Bmp/grungeBorderInverse-white.png) no-repeat bottom;
    background-size: 100% auto;

    @media all and (max-width: 649px) {
        padding-top: 8%;
        background-size: 150% auto;
    }
}

@mixin grungeSectionBorder-brown {
    @include grungeSectionBorder;

    background-image: url(../../Images/Bmp/grungeBorderInverse-brown.png);
}

@mixin grungeSectionBorder-beige {
    @include grungeSectionBorder;

    background-image: url(../../Images/Bmp/grungeBorderInverse-beige.png);
}

@mixin texture-overlay {
    &:before {
        content: "";
        position: fixed;
        z-index: 2;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: url(../../Images/Bmp/karton.png);
        pointer-events: none;
        opacity: 0.1;
    }
}

@mixin patch-big {
    &:before {
        content: "";

        @include centerVertHorz;

        position: absolute;
        z-index: 1;
        width: 60vw;
        height: 60vw;
        max-width: 1000px;
        max-height: 1000px;
        background: url(../../Images/Bmp/patch.png) no-repeat center center;
        background-size: contain;
        pointer-events: none;
        opacity: 0.55;
        mix-blend-mode: multiply;
    }
}

@mixin gradientBrown {
    background: linear-gradient(to bottom, transparentize($brown-dark, 1) 0%, transparentize($brown-dark, 0) 100%);
}

@mixin gradientBrown-top {
    background: linear-gradient(to top, transparentize($brown-dark, 1) 0%, transparentize($brown-dark, 0) 100%);
    background-size: 100% 10vh;
}

@mixin gradientBlack {
    background: linear-gradient(to bottom, transparentize(#000, 1) 0%, transparentize(#000, 0) 100%);
}
