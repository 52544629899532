section.hasPatch {
    padding: 30vw 0 17vw;
    overflow: hidden;

    @media all and (min-width: 1921px) {
        padding: 42rem 0 27rem;
    }

    .patch-header {
        position: relative;
        z-index: 1;
        font-size: calc(18px + 5.7vw);

        @include patch-big;

        h1,
        .h1,
        h2,
        .h2 {
            hyphens: none;
            font-style: italic;
            line-height: 1;
            text-align: center;
            position: relative;
            z-index: 2;
        }

        h1,
        .h1 {
            font-size: 1em;
            font-weight: 600;
            margin: 0;
        }

        h2,
        .h2 {
            font-size: 0.4em;
            font-weight: 400;
            margin: 0.75em 0 3em;
        }

        @media all and (min-width: $maxPageWidth) {
            font-size: 1rem;

            h1,
            .h1 {
                font-size: 12rem;
            }

            h2,
            .h2 {
                font-size: 4rem;
            }
        }

        @media all and (max-width: 649px) {
            h2,
            .h2 {
                font-size: 0.68em;
                padding: 0 2em;
                color: #857566;
                margin-top: 0.3em;
            }
        }

        @media all and (max-width: 400px) {
            h2,
            .h2 {
                padding: 0 1em;
            }
        }
    }

    .patch-content {
        position: relative;
        z-index: 2;
    }

    + section.textimage {
        margin-top: -17vw;
    }
}
