.hero {
    position: relative;
    z-index: 19;
    overflow: hidden;
    padding: 0;
    //height:70vh;
    //min-height: 600px;
    &:after {
        @include roundSectionBorder;
    }

    &:before {
        content: "";
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 0;
        width: 100%;
        padding-top: 15%;
        opacity: 0.4;

        @include gradientBlack;

        pointer-events: none;
    }

    .claim {
        position: absolute;
        z-index: 100;
        right: 0;
        top: 20%;
        margin: 0;
        width: 33.333%;
        padding: 0 0 0 2.5rem;

        @media all and (min-width: 1700px) {
            padding: 0;
            right: 2rem;
        }

        li {
            text-align: left;
            position: relative;
            z-index: 100;
            color: #fff;
            line-height: 1.35;
            font-weight: 500;
            font-style: italic;
            font-size: calc(16px + 1.75vw);
            margin: 0;
            text-shadow: 0 -2px 5px transparentize($brown-darkest, 0.4);

            @media all and (min-width: 1921px) {
                font-size: 5rem;
            }

            span {
                display: inline-block;
                margin-right: 0.5em;
                font-size: 0.85em;
                color: #fff;
            }
        }

        @media all and (max-width: 1023px) {
            display: none;
            width: auto;
            margin-right: 3rem;
        }
    }

    .uk-slideshow-items {
        li {
            margin: 0;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        @media screen and (orientation: landscape) {
            min-height: 50vw;
        }

        @media screen and (orientation: portrait) {
            min-height: 75vw;
        }

        @media screen and (orientation: landscape) and (min-width: 1921px) {
            min-height: 960px;
        }

        @media screen and (orientation: portrait) and (min-width: 1921px) {
            min-height: 1440px;
        }
    }
}
