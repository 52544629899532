$font-path: '../../Fonts/naturel' !default;

/* fira-sans-condensed-200 - latin */
@font-face {
    font-family: 'naturel';
    font-style: normal;
    font-weight: 200;
    src:
        url('#{$font-path}/naturel-200.woff2') format('woff2'),
        url('#{$font-path}/naturel-200.woff') format('woff'),
        url('#{$font-path}/naturel-200.ttf') format('truetype');
}

/* fira-sans-condensed-200italic - latin */
@font-face {
    font-family: 'naturel';
    font-style: italic;
    font-weight: 200;
    src:
        url('#{$font-path}/naturel-200italic.woff2') format('woff2'),
        url('#{$font-path}/naturel-200italic.woff') format('woff'),
        url('#{$font-path}/naturel-200italic.ttf') format('truetype');
}


/* fira-sans-condensed-300 - latin */
@font-face {
    font-family: 'naturel';
    font-style: normal;
    font-weight: 300;
    src:
        url('#{$font-path}/naturel-300.woff2') format('woff2'),
        url('#{$font-path}/naturel-300.woff') format('woff'),
        url('#{$font-path}/naturel-300.ttf') format('truetype');
}

/* fira-sans-condensed-regular - latin */
@font-face {
    font-family: 'naturel';
    font-style: normal;
    font-weight: 400;
    src:
        url('#{$font-path}/naturel-regular.woff2') format('woff2'),
        url('#{$font-path}/naturel-regular.woff') format('woff'),
        url('#{$font-path}/naturel-regular.ttf') format('truetype');
}

/* fira-sans-condensed-300italic - latin */
@font-face {
    font-family: 'naturel';
    font-style: italic;
    font-weight: 300;
    src:
        url('#{$font-path}/naturel-300italic.woff2') format('woff2'),
        url('#{$font-path}/naturel-300italic.woff') format('woff'),
        url('#{$font-path}/naturel-300italic.ttf') format('truetype');
}

/* fira-sans-condensed-italic - latin */
@font-face {
    font-family: 'naturel';
    font-style: italic;
    font-weight: 400;
    src:
        url('#{$font-path}/naturel-italic.woff2') format('woff2'),
        url('#{$font-path}/naturel-italic.woff') format('woff'),
        url('#{$font-path}/naturel-italic.ttf') format('truetype');
}

/* fira-sans-condensed-500 - latin */
@font-face {
    font-family: 'naturel';
    font-style: normal;
    font-weight: 500;
    src:
        url('#{$font-path}/naturel-500.woff2') format('woff2'),
        url('#{$font-path}/naturel-500.woff') format('woff'),
        url('#{$font-path}/naturel-500.ttf') format('truetype');
}

/* fira-sans-condensed-500italic - latin */
@font-face {
    font-family: 'naturel';
    font-style: italic;
    font-weight: 500;
    src:
        url('#{$font-path}/naturel-500italic.woff2') format('woff2'),
        url('#{$font-path}/naturel-500italic.woff') format('woff'),
        url('#{$font-path}/naturel-500italic.ttf') format('truetype');
}

/* fira-sans-condensed-600 - latin */
@font-face {
    font-family: 'naturel';
    font-style: normal;
    font-weight: 600;
    src:
        url('#{$font-path}/naturel-600.woff2') format('woff2'),
        url('#{$font-path}/naturel-600.woff') format('woff'),
        url('#{$font-path}/naturel-600.ttf') format('truetype');
}

/* fira-sans-condensed-700 - latin */
@font-face {
    font-family: 'naturel';
    font-style: normal;
    font-weight: 700;
    src:
        url('#{$font-path}/naturel-700.woff2') format('woff2'),
        url('#{$font-path}/naturel-700.woff') format('woff'),
        url('#{$font-path}/naturel-700.ttf') format('truetype');
}

/* fira-sans-condensed-700italic - latin */
@font-face {
    font-family: 'naturel';
    font-style: italic;
    font-weight: 700;
    src:
        url('#{$font-path}/naturel-700italic.woff2') format('woff2'),
        url('#{$font-path}/naturel-700italic.woff') format('woff'),
        url('#{$font-path}/naturel-700italic.ttf') format('truetype');
}

/* fira-sans-condensed-600italic - latin */
@font-face {
    font-family: 'naturel';
    font-style: italic;
    font-weight: 600;
    src:
        url('#{$font-path}/naturel-600italic.woff2') format('woff2'),
        url('#{$font-path}/naturel-600italic.woff') format('woff'),
        url('#{$font-path}/naturel-600italic.ttf') format('truetype');
}

/* fira-sans-condensed-800 - latin */
@font-face {
    font-family: 'naturel';
    font-style: normal;
    font-weight: 800;
    src:
        url('#{$font-path}/naturel-800.woff2') format('woff2'),
        url('#{$font-path}/naturel-800.woff') format('woff'),
        url('#{$font-path}/naturel-800.ttf') format('truetype');
}

/* fira-sans-condensed-800italic - latin */
@font-face {
    font-family: 'naturel';
    font-style: italic;
    font-weight: 800;
    src:
        url('#{$font-path}/naturel-800italic.woff2') format('woff2'),
        url('#{$font-path}/naturel-800italic.woff') format('woff'),
        url('#{$font-path}/naturel-800italic.ttf') format('truetype');
}
